<!--
    @name: TaskEngineList
    @description：TaskEngineList
    @author: ZengWei
    @date: 2022-06-23 17:52
-->
<template>
  <div style="height: 100%">
    <TaskTemplate :disabled="false"></TaskTemplate>
  </div>
</template>

<script>
import TaskTemplate from "@/custom-component/task-flow/TaskTemplate";
export default {
  name: "TaskEngineList",
  components: {TaskTemplate},
  props: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
